.CartLinkFixed {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  position: fixed;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  background: var(--primary-color);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  * {
    color: #FFF;
  }
  &__added-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__extra-size {
    height: 50px;
  }
  &__bag {
    width: 32px;
    height: 32px;
    position: relative;
    .bag {
      &__icon {
        font-size: 32px;
      }
      &__length {
        top: 0;
        right: -7px;
        width: 22px;
        height: 22px;
        display: flex;
        font-size: 12px;
        font-weight: 500;
        background: #FFF;
        position: absolute;
        border-radius: 22px;
        align-items: center;
        justify-content: center;
        color: var(--primary-color);
      }
    }
  }
}