.Menu {
  display: flex;
  flex-direction: column;
  &__filters {
    width: 100%;
    height: 60px;
    display: flex;
    margin: 30px 0 45px 0;
    padding: 0 20px;
    align-items: center;
    border: 1px solid gray;
    justify-content: space-between;
    .filters {
      &__icon {
        font-size: 24px;
      }
    }
  }
}