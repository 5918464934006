.UITextWithInfos {
  position: relative;
  display: inline-flex;
  &__text {
    margin: 0 15px 0 5px;
    color: var(--primary-color);
  }
  &__button {
    top: -2px;
    right: 5px;
    font-size: 14px;
    position: absolute;
  }
  &__extra-info {
    left: 100%;
    width: 150px;
    bottom: 100%;
    display: flex;
    padding: 10px;
    font-size: 12px;
    background: #FFF;
    position: absolute;
    box-shadow: var(--default-box-shadow);
  }
}