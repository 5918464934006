.CartItem {
  padding-bottom: 10px;
  &:not(:first-of-type) {
    padding-top: 20px;
    border-top: 1px solid var(--default-gray);
  }
  &__description {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    .description {
      &__infos {
        display: flex;
        min-height: 70px;
        flex-direction: column;
        align-items: flex-start;
        .infos {
          &__name {
            font-weight: 600;
            text-align: start;
          }
          &__extra-infos {
            display: flex;
            margin-left: 10px;
            flex-direction: column;
            align-items: flex-start;
            .extra-infos {
              &__info {
                text-align: start;
              }
            }
          }
          &__price {
            font-weight: 600;
            margin-top: auto;
          }
        }
      }
      &__image-container {
        width: 70px;
        height: 70px;
        position: relative;
        .image-container {
          &__image {
            width: 70px;
            height: 70px;
            object-fit: cover;
          }
          &__amount {
            right: 5px;
            bottom: 5px;
            width: 30px;
            height: 30px;
            display: flex;
            color: #FFF;
            position: absolute;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            background: var(--primary-color);
          }
          &__weight {
            right: 5px;
            bottom: 5px;
            height: 30px;
            display: flex;
            color: #FFF;
            padding: 5px 10px;
            position: absolute;
            border-radius: 30px;
            align-items: center;
            justify-content: center;
            background: var(--primary-color);
          }
        }
      }
    }
  }
  &__actions {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
    .actions {
      &__delete {
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid red;
      }
    }
  }
}