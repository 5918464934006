.UIModal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: auto;
    max-width: 600px;
    min-height: 200px;
    background: #FFF;
    padding: 20px 36px;
    position: relative;
    flex-direction: column;
    box-shadow: 
      0px 11px 15px -7px rgba(0,0,0,0.2), 
      0px 24px 38px 3px rgba(0,0,0,0.14), 
      0px 9px 46px 8px rgba(0,0,0,0.12);
  }

  &--hide {
    display: none;
  }
}
