.MenuItem {
  display: flex;
  min-height: 100px;
  padding: 10px 20px;
  position: relative;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  border: 1px solid var(--default-gray);

  &__infos {
    display: flex;
    flex-direction: column;
    width: calc(100% - 80px);
    .infos {
      &__name {
        font-size: 18px;
        color: var(--text-color);
      }
      &__description {
        flex-wrap: wrap;
        font-size: 14px;
        min-height: 40px;
        max-height: 42px;
        overflow: hidden;
        line-height: 21px;
        align-items: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
        -webkit-box-orient: vertical;
        color: var(--text-secondary-color);
      }
    }
  }

  &__image {
    right: 20px;
    width: 70px;
    height: 70px;
    object-fit: cover;
    position: absolute;
  }
}