.ProductObservation {
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
  &--empty-space {
    margin-bottom: 20px;
  }
  &__header {
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
    .header {
      &__title {
        display: flex;
        font-weight: 500;
        align-items: center;
        .title {
          &__icon {
            margin-right: 5px;
          }
        }
      }
      &__length {
        color: gray;
      }
    }
  }
  &__textarea {
    resize: none;
    height: 125px;
    padding: 10px;
    font-size: 16px;
  }
}