.CartHeader {
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--default-gray);

  &__close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__title {
    margin: auto;
  }
}