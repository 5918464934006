.UIFixedBottom {
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  position: fixed;
  background:#FFF;
  padding: 15px 20px 15px 20px;
  &::before {
    content: "";
    left: 0;
    top: -10px;
    z-index: 10;
    width: 100%;
    height: 10px;
    display: flex;
    position: absolute;
    background: linear-gradient(180deg,transparent,rgba(0,0,0,.07) 76.14%);
    // background: red;
  }
}