/* purgecss start ignore */

.UIModal{
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:flex;
  z-index:1000;
  position:fixed;
  align-items:center;
  justify-content:center;
  background:rgba(0,0,0,.5)
}

.UIModal__container{
  width:100%;
  height:100%;
  display:flex;
  overflow:auto;
  max-width:600px;
  min-height:200px;
  background:#fff;
  padding:20px 36px;
  position:relative;
  flex-direction:column;
  box-shadow:0px 11px 15px -7px rgba(0,0,0,.2),0px 24px 38px 3px rgba(0,0,0,.14),0px 9px 46px 8px rgba(0,0,0,.12)
}

.UIModal--hide{
  display:none
}

/* purgecss end ignore */
/* purgecss start ignore */

.UIBottomModal{
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:flex;
  z-index:1000;
  position:fixed;
  align-items:flex-end;
  justify-content:center;
  background:rgba(0,0,0,.5)
}

.UIBottomModal__container{
  width:100%;
  display:flex;
  overflow:auto;
  background:#fff;
  padding:20px 36px;
  position:relative;
  flex-direction:column;
  box-shadow:0px 11px 15px -7px rgba(0,0,0,.2),0px 24px 38px 3px rgba(0,0,0,.14),0px 9px 46px 8px rgba(0,0,0,.12)
}

.UIBottomModal__title{
  display:flex;
  font-weight:bold;
  text-align:center;
  align-items:center;
  margin-bottom:20px;
  justify-content:center
}

.UIBottomModal__title--double{
  justify-content:space-between
}

.UIBottomModal--hide{
  display:none
}

/* purgecss end ignore */
/* purgecss start ignore */

.UIFixedBottom{
  left:0;
  bottom:0;
  z-index:10;
  width:100%;
  display:flex;
  position:fixed;
  background:#fff;
  padding:15px 20px 15px 20px
}

.UIFixedBottom::before{
  content:"";
  left:0;
  top:-10px;
  z-index:10;
  width:100%;
  height:10px;
  display:flex;
  position:absolute;
  background:linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.07) 76.14%)
}

/* purgecss end ignore */
/* purgecss start ignore */

.UITextWithInfos{
  position:relative;
  display:inline-flex
}

.UITextWithInfos__text{
  margin:0 15px 0 5px;
  color:var(--primary-color)
}

.UITextWithInfos__button{
  top:-2px;
  right:5px;
  font-size:14px;
  position:absolute
}

.UITextWithInfos__extra-info{
  left:100%;
  width:150px;
  bottom:100%;
  display:flex;
  padding:10px;
  font-size:12px;
  background:#fff;
  position:absolute;
  box-shadow:var(--default-box-shadow)
}

/* purgecss end ignore */
/* purgecss start ignore */

.CartItem{
  padding-bottom:10px
}

.CartItem:not(:first-of-type){
  padding-top:20px;
  border-top:1px solid var(--default-gray)
}

.CartItem__description{
  width:100%;
  height:100%;
  display:flex;
  position:relative;
  justify-content:space-between
}

.CartItem__description .description__infos{
  display:flex;
  min-height:70px;
  flex-direction:column;
  align-items:flex-start
}

.CartItem__description .description__infos .infos__name{
  font-weight:600;
  text-align:start
}

.CartItem__description .description__infos .infos__extra-infos{
  display:flex;
  margin-left:10px;
  flex-direction:column;
  align-items:flex-start
}

.CartItem__description .description__infos .infos__extra-infos .extra-infos__info{
  text-align:start
}

.CartItem__description .description__infos .infos__price{
  font-weight:600;
  margin-top:auto
}

.CartItem__description .description__image-container{
  width:70px;
  height:70px;
  position:relative
}

.CartItem__description .description__image-container .image-container__image{
  width:70px;
  height:70px;
  object-fit:cover
}

.CartItem__description .description__image-container .image-container__amount{
  right:5px;
  bottom:5px;
  width:30px;
  height:30px;
  display:flex;
  color:#fff;
  position:absolute;
  border-radius:50%;
  align-items:center;
  justify-content:center;
  background:var(--primary-color)
}

.CartItem__description .description__image-container .image-container__weight{
  right:5px;
  bottom:5px;
  height:30px;
  display:flex;
  color:#fff;
  padding:5px 10px;
  position:absolute;
  border-radius:30px;
  align-items:center;
  justify-content:center;
  background:var(--primary-color)
}

.CartItem__actions{
  display:flex;
  margin-top:10px;
  align-items:center;
  justify-content:space-between
}

.CartItem__actions .actions__delete{
  padding:5px 10px;
  border-radius:5px;
  border:1px solid red
}

/* purgecss end ignore */
/* purgecss start ignore */

.CartItems{
  width:100%;
  display:flex;
  flex-direction:column
}

.CartItems__empty{
  text-align:center
}

.CartItems__actions .UIBottomModal__title{
  color:var(--primary-color)
}

.CartItems__actions .actions__button{
  height:50px;
  display:flex;
  margin:0 -36px;
  font-size:14px;
  font-weight:500;
  align-items:center;
  justify-content:center;
  text-decoration:underline
}

.CartItems__actions .actions__button:not(:last-child){
  border-bottom:1px solid var(--default-gray)
}

/* purgecss end ignore */
/* purgecss start ignore */

.CartValues__value{
  display:flex;
  font-size:12px;
  align-items:center;
  justify-content:space-between
}

.CartValues__value:not(:first-of-type){
  margin:10px 0
}

.CartValues__value:last-of-type{
  margin-bottom:0
}

.CartValues__total{
  font-size:16px;
  font-weight:500
}

.CartValues .cupom{
  color:green;
  font-size:14px;
  font-weight:500
}

/* purgecss end ignore */
/* purgecss start ignore */

.CartHeader{
  display:flex;
  margin-bottom:30px;
  padding-bottom:10px;
  border-bottom:1px solid var(--default-gray)
}

.CartHeader__close-icon{
  display:flex;
  align-items:center;
  justify-content:center
}

.CartHeader__close-icon svg{
  width:20px;
  height:20px
}

.CartHeader__title{
  margin:auto
}

/* purgecss end ignore */
/* purgecss start ignore */

.CartAddItem{
  display:flex;
  padding:10px;
  margin-top:30px;
  align-items:center;
  justify-content:center;
  border-top:1px solid var(--default-gray);
  border-bottom:2px solid var(--default-gray)
}

/* purgecss end ignore */
/* purgecss start ignore */

.Product{
  padding:0 var(--grid-margin-space)
}

/* purgecss end ignore */
/* purgecss start ignore */

.ProductDescription{
  margin-bottom:30px
}

.ProductDescription__description{
  white-space:pre-line
}

/* purgecss end ignore */
/* purgecss start ignore */

.ProductObservation{
  display:flex;
  margin-bottom:30px;
  flex-direction:column
}

.ProductObservation--empty-space{
  margin-bottom:20px
}

.ProductObservation__header{
  display:flex;
  margin-bottom:5px;
  justify-content:space-between
}

.ProductObservation__header .header__title{
  display:flex;
  font-weight:500;
  align-items:center
}

.ProductObservation__header .header__title .title__icon{
  margin-right:5px
}

.ProductObservation__header .header__length{
  color:gray
}

.ProductObservation__textarea{
  resize:none;
  height:125px;
  padding:10px;
  font-size:16px
}

/* purgecss end ignore */
/* purgecss start ignore */

.ProductSelectionGroup{
  padding-bottom:20px;
  margin:0 calc(-1*var(--grid-margin-space)) 0 calc(-1*var(--grid-margin-space))
}

.ProductSelectionGroup__options{
  display:flex;
  flex-direction:column
}

/* purgecss end ignore */
/* purgecss start ignore */

.CartLinkFixed{
  left:0;
  bottom:0;
  width:100%;
  height:50px;
  display:flex;
  position:fixed;
  padding:0 20px;
  align-items:center;
  justify-content:space-between;
  background:var(--primary-color);
  box-shadow:0 0 4px 0 rgba(0,0,0,.15)
}

.CartLinkFixed *{
  color:#fff
}

.CartLinkFixed__added-item{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center
}

.CartLinkFixed__extra-size{
  height:50px
}

.CartLinkFixed__bag{
  width:32px;
  height:32px;
  position:relative
}

.CartLinkFixed__bag .bag__icon{
  font-size:32px
}

.CartLinkFixed__bag .bag__length{
  top:0;
  right:-7px;
  width:22px;
  height:22px;
  display:flex;
  font-size:12px;
  font-weight:500;
  background:#fff;
  position:absolute;
  border-radius:22px;
  align-items:center;
  justify-content:center;
  color:var(--primary-color)
}

/* purgecss end ignore */
/* purgecss start ignore */

/* purgecss end ignore */
/* purgecss start ignore */

.MenuItem{
  display:flex;
  min-height:100px;
  padding:10px 20px;
  position:relative;
  align-items:center;
  margin-bottom:15px;
  justify-content:space-between;
  border:1px solid var(--default-gray)
}

.MenuItem__infos{
  display:flex;
  flex-direction:column;
  width:calc(100% - 80px)
}

.MenuItem__infos .infos__name{
  font-size:18px;
  color:var(--text-color)
}

.MenuItem__infos .infos__description{
  flex-wrap:wrap;
  font-size:14px;
  min-height:40px;
  max-height:42px;
  overflow:hidden;
  line-height:21px;
  align-items:center;
  display:-webkit-box;
  -webkit-line-clamp:2;
  text-overflow:ellipsis;
  width:calc(100% - 20px);
  -webkit-box-orient:vertical;
  color:var(--text-secondary-color)
}

.MenuItem__image{
  right:20px;
  width:70px;
  height:70px;
  object-fit:cover;
  position:absolute
}

/* purgecss end ignore */
/* purgecss start ignore */

.Menu{
  display:flex;
  flex-direction:column
}

.Menu__filters{
  width:100%;
  height:60px;
  display:flex;
  margin:30px 0 45px 0;
  padding:0 20px;
  align-items:center;
  border:1px solid gray;
  justify-content:space-between
}

.Menu__filters .filters__icon{
  font-size:24px
}

/* purgecss end ignore */
*, ::before, ::after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity))
}

.tw-relative {
  position: relative !important
}

.tw-mb-4 {
  margin-bottom: 1rem !important
}

.tw-flex {
  display: flex !important
}

.tw-hidden {
  display: none !important
}

.tw-max-h-screen {
  max-height: 100vh !important
}

.tw-w-full {
  width: 100% !important
}

@keyframes tw-spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes tw-ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes tw-pulse {
  50% {
    opacity: .5
  }
}

@keyframes tw-bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.tw-cursor-pointer {
  cursor: pointer !important
}

.tw-flex-col {
  flex-direction: column !important
}

.tw-items-center {
  align-items: center !important
}

.tw-justify-center {
  justify-content: center !important
}

.tw-gap-1 {
  gap: 0.25rem !important
}

.tw-gap-4 {
  gap: 1rem !important
}

.tw-overflow-hidden {
  overflow: hidden !important
}

.tw-overflow-x-auto {
  overflow-x: auto !important
}

.tw-overflow-y-auto {
  overflow-y: auto !important
}

.tw-rounded-md {
  border-radius: 0.375rem !important
}

.tw-border {
  border-width: 1px !important
}

.tw-border-solid {
  border-style: solid !important
}

.tw-border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important
}

.tw-p-2 {
  padding: 0.5rem !important
}

.tw-pb-0 {
  padding-bottom: 0px !important
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
  .lg\:tw-flex {
    display: flex !important
  }

  .lg\:tw-hidden {
    display: none !important
  }
}

@media (min-width: 1280px) {
}

@media (min-width: 1536px) {
}
