.CartValues {
  &__value {
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
    &:not(:first-of-type) {
      margin: 10px 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__total {
    font-size: 16px;
    font-weight: 500;
  }
  .cupom {
    color: green;
    font-size: 14px;
    font-weight: 500;
  }
}