.CartItems {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__empty {
    text-align: center;
  }

  &__actions {
    .UIBottomModal__title {
      color: var(--primary-color);
    }
    .actions {
      &__button {
        height: 50px;
        display: flex;
        margin: 0 -36px;
        font-size: 14px;
        font-weight: 500;
        align-items: center;
        justify-content: center;
        text-decoration: underline;
        &:not(:last-child) {
          border-bottom: 1px solid var(--default-gray);
        }
      }
    }
  }
}